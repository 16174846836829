<template>
  <div :style="{ position: 'relative' }">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :header="`Converter Reserva em Intervenção`"
      :modal="true"
      :closable="false"
    >
      <Loading
        :active.sync="loading"
        :isFullPage="false"
        :zIndex="9000"
      ></Loading>

      <form
        name="booking-to-intervention-form"
        class="p-col-12"
        @submit.prevent="save"
        style="min-height: 50vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <InputText
                type="text"
                name="username"
                v-model="intervetion.get_user.username"
                :disabled="true"
              />
              <label for="username">Técnico</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <InputText
                type="text"
                name="day"
                v-model="intervetion.date"
                :disabled="true"
              />
              <label for="day">Dia</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <InputText
                type="text"
                name="start"
                v-model="intervetion.start"
                :disabled="true"
              />
              <label for="start">Inicio</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-3 p-mt-2">
            <span class="p-float-label">
              <InputText
                type="text"
                name="end"
                v-model="intervetion.end"
                :disabled="true"
              />
              <label for="end">Fim</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12">
            <span class="p-float-label">
              <Dropdown
                ref="searchInput"
                :class="'form-control'"
                id="search"
                :inputId="'searchInput'"
                :options="searchItems"
                :optionLabel="getSearchLabel"
                :optionValue="'patNumber'"
                optionDisabled="disabled"
                :filter="true"
                name="searchInput"
                @input="patChanged"
              >
                <template #option="slotProps">
                  <div
                    v-if="$isMobile()"
                    class="options-search-responsive p-ml-2"
                  >
                    <b v-if="slotProps.option.typename != 'Redmine'"> PAT: </b>
                    <b v-else> Issue: </b>
                    {{ slotProps.option.patNumber }}
                    | {{ slotProps.option.typename }}
                    <br />
                    <span v-if="slotProps.option.patTitle !== ''">
                      <b>Título: </b> {{ slotProps.option.patTitle }}
                    </span>
                    <span v-if="slotProps.option.patDescription !== ''">
                      <b>Descrição: </b>
                      {{ slotProps.option.patDescription }}
                    </span>
                    <span v-if="slotProps.option.disabled" class="p-error">
                      <b
                        >Instalação sem Data. Marque Através do Menu
                        Suporte->Instalações</b
                      >
                    </span>
                  </div>
                  <div v-else class="options-search">
                    {{ slotProps.option.typename }} | <b>Projeto: </b>
                    {{ slotProps.option.projectName }} |
                    <b v-if="slotProps.option.typename != 'Redmine'"> PAT: </b>
                    <b v-else> Issue: </b>
                    {{ slotProps.option.patNumber }}
                    <span v-if="slotProps.option.patTitle !== ''">
                      <br /><b>Título: </b> {{ slotProps.option.patTitle }}
                    </span>
                    <span v-if="slotProps.option.patDescription !== ''">
                      <br /><b>Descrição: </b>
                      {{ slotProps.option.patDescription }}
                    </span>
                    <span v-if="slotProps.option.disabled" class="p-error">
                      <br />
                      <b
                        >Instalação sem Data. Marque Através do Menu
                        Suporte->Instalações</b
                      >
                    </span>
                  </div>
                </template>
              </Dropdown>
              <label for="inputtext">Pesquisar PAT/Issue</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-4 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                v-model="intervetion.type"
                name="type"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('type') },
                  'form-control',
                ]"
                :options="getOptions('typeId')"
                :optionLabel="'typename'"
                :optionValue="'typeId'"
                :dataKey="'typeId'"
                :filter="true"
                :required="true"
                @input="typeChanged"
                :showClear="true"
              />
              <label for="inputtext">Tipo de tarefa</label>
            </span>
            <small v-if="errors.has('type')" class="p-error" role="alert"
              >Tipo de tarefa é obrigatório</small
            >
          </div>
          <div class="p-field p-col-12 p-md-4 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                v-model="projectSelected"
                v-validate="'required'"
                name="project"
                v-bind:class="[
                  { 'p-invalid': errors.has('project') },
                  'form-control',
                ]"
                :inputId="'project'"
                :options="getOptions('projectId')"
                :optionLabel="'projectName'"
                :optionValue="'projectId'"
                :dataKey="'projectId'"
                :filter="true"
                @input="projectChanged"
                :showClear="true"
              />
              <label for="inputtext">Projeto</label>
            </span>
            <small v-if="errors.has('project')" class="p-error" role="alert"
              >Projeto é obrigatório</small
            >
          </div>
          <div class="p-field p-col-12 p-md-4 p-mt-3">
            <span class="p-float-label">
              <Dropdown
                v-model="intervetion.patNumber"
                v-validate="'required'"
                name="pat"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('pat'),
                  },
                  'form-control',
                ]"
                :inputId="'pat'"
                :options="getOptions('patNumber')"
                :optionLabel="getPatLabel"
                :optionValue="'patNumber'"
                :optionDisabled="'disabled'"
                :dataKey="'patNumber'"
                :filter="true"
                @input="patChanged"
                :showClear="true"
              >
                <template #option="slotProps">
                  <div class="p-dropdown-pat-option">
                    <div>
                      <b v-if="slotProps.option.typeId == 4">Issue: </b>
                      <b v-else>PAT: </b>
                      {{ slotProps.option.patNumber }}
                    </div>
                    <br v-if="$isMobile()" />
                    <div v-if="slotProps.option.patTitle !== ''">
                      <b>Título: </b>{{ slotProps.option.patTitle }}
                    </div>
                    <br v-if="$isMobile()" />
                    <div v-if="slotProps.option.patDescription !== ''">
                      <b>Descrição: </b>{{ slotProps.option.patDescription }}
                    </div>
                    <div v-if="slotProps.option.disabled" class="p-error">
                      <b
                        >Instalação sem Data. Marque Através do Menu
                        Suporte->Instalações</b
                      >
                    </div>
                  </div>
                </template>
              </Dropdown>
              <label for="inputtext">{{
                intervetion.type == 4 ? "Issue" : "PAT"
              }}</label>
            </span>
            <small v-if="errors.has('pat')" class="p-error" role="alert">
              {{ intervetion.type == 4 ? "Issue" : "PAT" }} é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                name="note"
                v-model="intervetion.note"
                :autoResize="true"
                rows="4"
              />
              <label for="note">Nota</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                name="resume"
                v-model="intervetion.resume"
                :autoResize="true"
                rows="4"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('resume'),
                  },
                  'form-control',
                ]"
              />
              <label for="resume">Trabalho Efetuado</label>
            </span>
            <small v-if="errors.has('resume')" class="p-error" role="alert">
              PAT é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                name="description"
                v-model="intervetion.description"
                :autoResize="true"
                rows="4"
              />
              <label for="description"
                >Pendente para a próxima intervenção</label
              >
            </span>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="save"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { getOnlyDate } from "../../helpers/helpers";
import interventionService from "../../services/intervention.service";
import taskService from "../../services/task.service";
import bookingService from "../../services/booking.service";
export default {
  name: "BookingToIntervention",
  props: ["show", "booking"],
  components: { Loading },
  watch: {
    async show() {
      if (this.show === true) {
        this.$validator.resume();
        this.reset();
        this.intervetion = { ...this.booking };
        this.intervetion.date = getOnlyDate(this.intervetion.date);
        this.intervetion.start = this.convertHours(this.intervetion.start);
        this.intervetion.end = this.convertHours(this.intervetion.end);
        this.intervetion.type = null;
      } else {
        this.reset();
      }
    },
  },
  data() {
    return {
      loading: false,
      intervetion: { get_user: {} },
      interventionTypes: [],
      searchItems: [],
      projectSelected: null,
    };
  },
  async created() {
    this.loading = true;
    await this.getInterventionTypes();
    this.loading = false;
  },
  methods: {
    reset() {
      this.intervetion = { get_user: {} };
      this.projectSelected = null;
      this.loading = false;
    },
    getDate(date) {
      return getOnlyDate(date);
    },
    getSearchLabel(info) {
      return `${info.typename} ${info.projectName} ${info.patNumber} ${info.patTitle} ${info.patDescription}`;
    },
    convertHours(hours) {
      let times = hours.split(":");
      return times[0] + ":" + times[1];
    },
    async getInterventionTypes() {
      let id = 0;
      interventionService.getInterventionTypes().then((response) => {
        for (let type of response) {
          type.projects = interventionService
            .getInterventionsByType(type.id)
            .then((interventions) => {
              this.interventionTypes.push(response);
              for (let project of interventions) {
                if (project == undefined) {
                  continue;
                }
                for (let pat of project.pats) {
                  this.searchItems.push({
                    id: id++,
                    typeId: type.id,
                    typename: type.name,
                    projectId: project.id,
                    projectName: project.name,
                    patNumber: pat.pat_number,
                    patTitle: pat.pat_title,
                    patDescription: pat.pat_description,
                    disabled:
                      pat.installation_date == "1900-01-01" ? true : false,
                  });
                }
              }
            });
        }
      });
    },
    patChanged(select) {
      this.intervetion.patNumber = select;
      if (select == null) {
        return;
      }
      this.intervetion.type = null;
      this.projectSelected = null;

      const equalPat = (opt) => {
        return opt.patNumber == this.intervetion.patNumber;
      };

      let items = this.getOptions("patNumber");
      let filter = items.filter(equalPat);
      this.intervetion.type = filter[0].typeId;
      this.projectSelected = filter[0].projectId;
    },
    getOptions(key) {
      let options = [];
      let allItems = this.searchItems;

      if (this.intervetion.type != null) {
        const equalType = (opt) => {
          return opt.typeId == this.intervetion.type;
        };
        allItems = allItems.filter(equalType);
      }

      if (this.projectSelected != null) {
        const equalProject = (opt) => {
          return opt.projectId == this.projectSelected;
        };
        allItems = allItems.filter(equalProject);
      }
      allItems.map((x) =>
        options.filter((a) => a[key] == x[key]).length > 0
          ? null
          : options.push(x)
      );
      return options;
    },
    typeChanged(select) {
      this.intervetion.patNumber = null;
      this.projectSelected = null;
      this.intervetion.type = select;
    },
    projectChanged(select) {
      this.intervetion.patNumber = null;
      this.projectSelected = select;

      if (select == null) {
        return;
      }

      const equalProject = (opt) => {
        return opt.projectId == this.projectSelected;
      };

      let items = this.getOptions("projectId");
      let filter = items.filter(equalProject);
      this.intervetion.type = filter[0].typeId;
    },
    getPatLabel(info) {
      let prefix = "PAT";
      if (info.typeId == 4) {
        prefix = "Issue";
      }
      return `${prefix} ${info.patNumber} - ${info.patDescription}`;
    },
    save() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        return this.saveIntervention();
      });
    },
    saveIntervention() {
      let interventionInfo = {
        type: this.intervetion.type,
        pat_number: this.intervetion.patNumber,
        start: `${this.intervetion.date} ${this.intervetion.start}`,
        end: `${this.intervetion.date} ${this.intervetion.end}`,
        resume: this.intervetion.resume,
        description: this.intervetion.description,
        user_id: this.intervetion.user_id,
        note: this.intervetion.note,
        from_support: true,
        convertToIntrevention: 1
      };
      taskService.createTask(interventionInfo).then((response) => {
        this.loading = false;
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro ao gravar",
            detail: "Ocorreu um erro ao gravar a tarefa",
            life: 3000,
          });
        }
        this.loading = true;
        bookingService
          .updatedBooking(this.intervetion.id, {
            intervention_id: response.id,
            convertToIntrevention: 1,
          })
          .then((update) => {
            this.loading = false;
            if (!update) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro ao gravar",
                detail: "Ocorreu um erro ao actualizar a reserva",
                life: 3000,
              });
            }
            this.$toast.add({
              severity: "success",
              summary: "Reserva convertida",
              detail: "A reserva foi convertida com sucesso",
              life: 3000,
            });
            return this.$emit("converted");
          });
      });
    },
    cancel() {
      this.$validator.pause();
      this.reset();
      return this.$emit("cancel");
    },
  },
};
</script>
<style>
.p-dropdown-items li.p-dropdown-item.p-disabled {
  background-color: rgba(255, 0, 0, 0.1) !important;
  cursor: not-allowed !important;
}
.p-dropdown-panel {
  width: 50%;
}
</style>
