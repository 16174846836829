<template>
  <div class="card p-shadow-6">
    <h1>Reservas sem Intervenção</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <BookingToIntervention
      :show="convertBooking.show"
      :booking="convertBooking.booking"
      v-on:converted="converted"
      v-on:cancel="canceled"
    />
    <div style="height: 65vh">
      <DataTable
        :value="bookingList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="date"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #loading> A carregar. Por favor Aguarde... </template>
        <Column
          field="date"
          filterField="date"
          header="Dia"
          sortable
          :headerStyle="{ 'min-width': '16%', 'max-width': '16%' }"
          :filterHeaderStyle="{ 'min-width': '16%', 'max-width': '16%' }"
          :bodyStyle="{ 'min-width': '16%', 'max-width': '16%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Converter em intervenção'"
              @click="convert(slotProps.data)"
              >{{ slotProps.data.startEnd }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column
          sortable
          field="get_user.username"
          header="Técnico"
          filterField="get_user.username"
          :headerStyle="{ 'min-width': '12%', 'max-width': '12%' }"
          :filterHeaderStyle="{ 'min-width': '12%', 'max-width': '12%' }"
          :bodyStyle="{ 'min-width': '12%', 'max-width': '12%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.get_user.username }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="note"
          header="Notas"
          filterField="note"
          :headerStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :filterHeaderStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :bodyStyle="{ 'min-width': '20%', 'max-width': '20%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.note }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="description"
          header="Pendente"
          filterField="description"
          :headerStyle="{ 'min-width': '18%', 'max-width': '18%' }"
          :filterHeaderStyle="{ 'min-width': '18%', 'max-width': '18%' }"
          :bodyStyle="{ 'min-width': '18%', 'max-width': '18%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.description }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="resume"
          header="Resumo"
          filterField="resume"
          :headerStyle="{ 'min-width': '17%', 'max-width': '17%' }"
          :filterHeaderStyle="{ 'min-width': '17%', 'max-width': '17%' }"
          :bodyStyle="{ 'min-width': '17%', 'max-width': '17%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.resume }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="get_create_user.username"
          header="Criado Por"
          filterField="get_create_user.username"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.get_create_user.username }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          :headerStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :filterHeaderStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :bodyStyle="{ 'min-width': '5%', 'max-width': '5%' }"
        >
          <template #body="slotProps">
            <Button
              v-if="can('deleteSupportBookibg')"
              icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text"
              @click="removeBooking(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api/";
import bookingService from "../services/booking.service";
import BookingToIntervention from "../components/support/BookingToIntervention.vue";
export default {
  name: "support-bookings",
  components: {
    BookingToIntervention,
  },
  data() {
    return {
      convertBooking: { show: false, booking: null },
      showForm: false,
      bookingList: [],
      loading: false,
      filters: {
        date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        "get_user.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        "get_create_user.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        resume: { value: null, matchMode: FilterMatchMode.CONTAINS },
        note: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  async created() {
    this.loading = true;
    await this.getBookingList();
    this.loading = false;
  },
  methods: {
    async getBookingList() {
      this.loading = true;
      let bookingList =
        await bookingService.getSupportBookingWithoutIntervention();
      bookingList = bookingList.map((booking) => {
        return {
          ...booking,
          startEnd: `${booking.date} ${booking.start} / ${booking.end}`,
          created_at: new Date(`${booking.created_at}`),
          date: new Date(`${booking.date}`),
        };
      });
      this.bookingList = bookingList;
      return (this.loading = false);
    },
    convert(booking) {
      this.convertBooking.booking = booking;
      this.convertBooking.show = true;
    },
    canceled() {
      this.convertBooking.show = false;
      this.convertBooking.booking = null;
    },
    converted() {
      let index = this.bookingList.findIndex(
        (c) => c.id == this.convertBooking.booking.id
      );
      this.bookingList.splice(index, 1);
      return this.canceled();
    },
    removeBooking(booking) {
      let title = `Apagar Reserva`;
      let message = `Tem a certeza que pretende remover a reserva? `;
      message += `Dia ${booking.startEnd}, `;
      message += `técnico ${booking.get_user.username}`;
      this.$confirm.require({
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.deleteBooking(booking);
        },
        reject: () => {
          return this.$emit("cancel");
        },
      });
    },
    deleteBooking(booking) {
      bookingService.removeBooking(booking.id).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao eliminar a reserva",
            life: 3000,
          });
        }
        this.$toast.add({
          severity: "success",
          summary: "Reserva eliminada",
          detail: "A reserva foi eliminada com sucesso",
          life: 3000,
        });
        let index = this.bookingList.findIndex((c) => c.id == booking.id);

        return this.bookingList.splice(index, 1);
      });
    },
  },
};
</script>
